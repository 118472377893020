// @flow

import React from 'react';
import { observer } from 'mobx-react';
import { graphql, StaticQuery } from 'gatsby';
import * as classNames from 'classnames';
import filter from 'lodash/filter';

import dashToCamelCase from '../../utils/dash-to-camel-case';
import styles from './style.module.scss';
import BlurUpImage from '../BlurUpImage';
import getImageUrl from "../../utils/get-image-url";

type FramedPhotoProps = {
  type: 'horizontal-black'
    | 'horizontal-green'
    | 'polaroid-one'
    | 'polaroid-two'
    | 'polaroid-three'
    | 'square-black'
    | 'square-green'
    | 'vertical-green-one'
    | 'vertical-green-two'
    | 'no-frame',
  src: string,
  srcSmall?: string,
}

const NEW_TYPE_MAP = {
  'polaroid-one': 'page-frame-1',
  'polaroid-two': 'page-frame-2',
  'polaroid-three': 'page-frame-3',
  'square-black': 'page-frame-1',
  'square-green': 'page-frame-2',
  'vertical-green-one': 'page-frame-1',
  'vertical-green-two': 'page-frame-1',
  'horizontal-green': 'page-frame-1',
  'horizontal-black': 'page-frame-2'
};

@observer
class FramedPhoto extends React.Component<FramedPhotoProps, any> {

  render() {

    const type = NEW_TYPE_MAP[this.props.type] || this.props.type;
    let typeCase = dashToCamelCase(type);
    if (this.props.type.includes('vertical')) typeCase = `${typeCase}Vert`;
    const wrapperClass = classNames({
      [styles.wrapper]: true,
      [styles[typeCase]]: true,
    });
    const imageClass = classNames({
      [styles.image]: true,
      [styles[typeCase]]: true,
    });

    return (
      <StaticQuery
        query={graphql`
            query {
              ui: contentfulUi(name: {eq: "UI"}) {
                photoFrames {
                  title
                  file {
                    url
                    details {
                      image {
                        height
                        width
                      }
                    }
                  }
                }
              }
            }
          `}
        render={data => {
          const frame: any = filter(data.ui.photoFrames, {title: type})[0];
          if (type === 'no-frame') {
            return (
              <div className={styles.container}>
                <BlurUpImage
                  srcSmall={this.props.srcSmall || ''}
                  src={this.props.src}/>
              </div>
            )
          }
          return (
            <div className={styles.container}>
              <div className={styles.ratio}
                   style={{
                     paddingTop: `${frame.file.details.image.height / frame.file.details.image.width * 100}%`
                   }}>
                <div className={wrapperClass}>
                  <BlurUpImage src={getImageUrl(frame)} />
                  <div className={imageClass}>
                    <BlurUpImage
                      src={this.props.src} />
                  </div>
                </div>
              </div>
            </div>
          )
        }}
      />
    )
  }
}

export default FramedPhoto;
