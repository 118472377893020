// @flow

import { observable, action, computed, reaction, toJS } from 'mobx';
import reduce from 'lodash/reduce';

import {JSON_GALLERY} from '../../constants/json';
import UiStore from '../ui-store';
import JsonStore from '../json-store';
import getImageUrl from '../../utils/get-image-url';

class GalleryStore {

  uiStore: UiStore;
  jsonStore: JsonStore;

  constructor(uiStore: UiStore,
              jsonStore: JsonStore) {
    this.uiStore = uiStore;
    this.jsonStore = jsonStore;

    reaction(
      () => this.mobile,
      () => {
        this.currentId === '2017' ? this.setContent(this.currentId) : this.setContentPage(this.currentId);
      }
    );
  }

  currentId: string = '2017';

  @observable content: any[] = [];
  @observable currentIndex: number = 0;
  @observable activeContainerIndex: number = 0;
  @observable fullContent: any[] = [];

  @observable contentTwo: any[] = [];
  @observable currentIndexTwo: number = 0;
  @observable activeContainerIndexTwo: number = 0;

  @action
  setContentPage = (id: string, currentIndex?: number=0): void => {
    this.currentId = id;
    const data = this.jsonStore.store.get(id);
    if (data && data.gallery && data.gallery.images) {
      this.fullContent = reduce(data.gallery.images,(arr: any[], image: any) => {
        arr.push({
          large: image,
          small: {
            file: {
              url: getImageUrl(image, true)
            }
          }
        });
        return arr;
      }, []);
      let count: number = 0;
      let masterCount: number = 0;
      if (!this.mobile) {
        this.content = reduce(this.fullContent, (acc: any[], item, index) => {
          if (count % 8 === 0) {
            acc.push([]);
            if (index !== 0) masterCount++;
          }
          acc[masterCount].push(item);
          count++;
          return acc;
        }, []);
      } else {
        this.content = reduce(this.fullContent, (acc: any[], item, index) => {
          if (count % 3 === 0) {
            acc.push([]);
            if (index !== 0) masterCount++;
          }
          acc[masterCount].push(item);
          count++;
          return acc;
        }, []);
      }
      // this.content[masterCount].pop();
    } else {
      this.content = [];
      return;
    }
    this.currentIndex = currentIndex;
    this.slideContent = [
      this.content[this.normalizeIndex(currentIndex - 1, this.content.length)],
      this.content[currentIndex],
      this.content[this.normalizeIndex(currentIndex + 1, this.content.length)],
    ];
    this.slidePositionX = ['-200vw', '0', '200vw'];
    this.slideOpacity = [0, 1, 0];
    this.activeContainerIndex = 1;
  };

  @action
  setContent = (id: string, currentIndex?: number=0): void => {
    this.currentId = `2017`;
    const data = this.jsonStore.store.get(JSON_GALLERY);
    if (data && data[JSON_GALLERY]) {
      const galleries = reduce(data[JSON_GALLERY].galleries, (acc, gallery) => {
        return [...acc, ...toJS(gallery.gallery)];
      }, []);
      this.fullContent = reduce(galleries,
        (acc: any[], item) => {
        if (item.large) acc.push(item);
        return acc;
      }, []);
      this.content = this.fullContent;
      this.contentTwo = reduce(this.fullContent, (acc, slide, index) => {
        if (index !== 0 && index % 30 === 0) acc.push([]);
        const current = acc[acc.length - 1];
        current.push(slide);
        return acc;
      }, [[]]);
    } else {
      this.content = [];
      this.contentTwo = [];
      return;
    }
    this.currentIndex = currentIndex;
    this.currentIndexTwo = 0;
    this.slideContent = [
      this.content[this.normalizeIndex(currentIndex - 1, this.content.length)],
      this.content[currentIndex],
      this.content[this.normalizeIndex(currentIndex + 1, this.content.length)],
    ];
    this.slidePositionX = ['-200vw', '0', '200vw'];
    this.slideOpacity = [0, 1, 0];
    this.activeContainerIndex = 1;
    this.slidePositionXTwo = ['-200vw', '0', '200vw'];
    this.slideOpacityTwo = [0, 1, 0];
    this.activeContainerIndexTwo = 1;
    this.slideContentTwo = [
      this.contentTwo[this.normalizeIndex(-1, this.contentTwo.length)],
      this.contentTwo[0],
      this.contentTwo[this.normalizeIndex(1, this.contentTwo.length)],
    ];
  };

  @action setSlide = (index): void => {
    this.currentIndex = index;
    const current = this.slidePositionX.indexOf('0');
    const previous = this.slidePositionX.indexOf('-200vw');
    const next = this.slidePositionX.indexOf('200vw');
    this.slideContent[current] = this.content[index];
    this.slideContent[previous] = this.content[this.normalizeIndex(index - 1, this.content.length)];
    this.slideContent[next] = this.content[this.normalizeIndex(index + 1, this.content.length)];
  };

  @action shiftLeftTwo = (): void => {
    let index: number = this.normalizeIndex(this.currentIndexTwo - 1, this.contentTwo.length);
    this.slidePositionXTwo = [
      this.slidePositionXTwo[1],
      this.slidePositionXTwo[2],
      this.slidePositionXTwo[0]
    ];
    this.slideOpacityTwo = [1, 1, 1];
    this.slidePositionXTwo.forEach((x: string, innerIndex: number) => {
      if (x === '0') {
        this.activeContainerIndexTwo = innerIndex;
      }
      if (x === '-200vw') {
        this.slideOpacityTwo[innerIndex] = 0;
        this.slideContentTwo[innerIndex] = this.contentTwo[this.normalizeIndex(index - 1, this.contentTwo.length)];
      }
    });
    this.currentIndexTwo = index;
  };

  @action shiftRightTwo = (): void => {
    let index: number = this.normalizeIndex(this.currentIndexTwo + 1, this.contentTwo.length);
    this.slidePositionXTwo = [
      this.slidePositionXTwo[2],
      this.slidePositionXTwo[0],
      this.slidePositionXTwo[1]
    ];
    this.slideOpacityTwo = [1, 1, 1];
    this.slidePositionXTwo.forEach((x: string, innerIndex: number) => {
      if (x === '0') {
        this.activeContainerIndexTwo = innerIndex;
      }
      if (x === '200vw') {
        this.slideOpacityTwo[innerIndex] = 0;
        this.slideContentTwo[innerIndex] = this.contentTwo[this.normalizeIndex(index + 1, this.contentTwo.length)];
      }
    });
    this.currentIndexTwo = index;
  };

  @action shiftLeft = (handleThumbs: boolean=false): void => {
    let index: number = this.normalizeIndex(this.currentIndex - 1, this.content.length);
    this.slidePositionX = [
      this.slidePositionX[1],
      this.slidePositionX[2],
      this.slidePositionX[0]
    ];
    this.slideOpacity = [1, 1, 1];
    this.slidePositionX.forEach((x: string, innerIndex: number) => {
      if (x === '0') {
        this.activeContainerIndex = innerIndex;
      }
      if (x === '-200vw') {
        this.slideOpacity[innerIndex] = 0;
        this.slideContent[innerIndex] = this.content[this.normalizeIndex(index - 1, this.content.length)];
      }
    });
    this.currentIndex = index;
    if (handleThumbs) {
      const minIndex = this.currentIndexTwo * 30;
      if (this.currentIndex < minIndex || this.currentIndex === this.fullContent.length - 1) {
        this.shiftLeftTwo();
      }
    }
  };

  @action shiftRight = (handleThumbs: boolean=false): void => {
    let index: number = this.normalizeIndex(this.currentIndex + 1, this.content.length);
    this.slidePositionX = [
      this.slidePositionX[2],
      this.slidePositionX[0],
      this.slidePositionX[1]
    ];
    this.slideOpacity = [1, 1, 1];
    this.slidePositionX.forEach((x: string, innerIndex: number) => {
      if (x === '0') {
        this.activeContainerIndex = innerIndex;
      }
      if (x === '200vw') {
        this.slideOpacity[innerIndex] = 0;
        this.slideContent[innerIndex] = this.content[this.normalizeIndex(index + 1, this.content.length)];
      }
    });
    this.currentIndex = index;
    if (handleThumbs) {
      const maxIndex = (this.currentIndexTwo + 1) * 30;
      if (this.currentIndex === maxIndex || this.currentIndex === 0) {
        this.shiftRightTwo();
      }
    }
  };

  normalizeIndex(index: number, length?: number): number {
    if (!length) length = this.content.length;
    if (index < 0) return length - 1;
    if (index > length - 1) return 0;
    return index;
  }

  @computed
  get contentLength(): number {
    return this.content.length;
  }

  @computed
  get mobile(): boolean {
    return this.uiStore.windowWidth <= 700;
  }

  @observable slideContent: any[] = [{}, {}, {}];
  @observable slideOpacity: number[] = [1, 1, 1];
  @observable slidePositionX: string[] = ['', '', ''];

  @observable slideContentTwo: any[] = [{}, {}, {}];
  @observable slideOpacityTwo: number[] = [1, 1, 1];
  @observable slidePositionXTwo: string[] = ['', '', ''];
}

export default GalleryStore;
